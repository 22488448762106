import { Typography, Grid, Box, Container, Button } from "@mui/material";
import { useCallback, useState } from "react";
import { sendSpin } from "../../services/InteractiveAnswerService";

import { FullScreen } from "@chiragrupani/fullscreen-react";

const SpinRobot = () => {
  const [isFullScreen, setFullScreen] = useState(false);

  const handleClick = useCallback(async (value) => {
    await sendSpin({ answer: value });
  }, []);

  return (
    <FullScreen
      isFullScreen={isFullScreen}
      onChange={(isFull) => {
        setFullScreen(isFull);
      }}
    >
      <Box sx={{ backgroundColor: "black", width: 1 }}>
        {isFullScreen === false && (
          <Button onClick={(e) => setFullScreen(!isFullScreen)}>
            Go Fullscreen
          </Button>
        )}
        <Box
          sx={{
            position: "absolute",
            zIndex: -4,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "black",
          }}
        ></Box>
        <Container>
          <Grid
            container
            spacing={1}
            sx={{ mt: { xs: 5, sm: 5, md: 5, lg: 0 } }}
          >
            <Grid
              item
              xs={12}
              sx={{
                height: {
                  lg: "450px",
                  md: "300px",
                  sm: "250px",
                  xl: "500px",
                  xs: "200px",
                },
                width: "100%",
              }}
            >
              <video
                src="https://game.shutterstudio.io/purple.mp4"
                loop
                muted
                autoPlay={true}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              ></video>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => handleClick(true)}
              >
                <Box
                  sx={{
                    border: "2.5px solid #A7B136",

                    borderRadius: "50%",
                    width: { md: "150px", sm: "135px", xs: "130px" },
                    height: { md: "150px", sm: "135px", xs: "130px" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    fontWeight={700}
                    sx={{
                      fontSize: {
                        md: "27px",
                        sm: "17px",
                        xs: "15px",
                      },
                    }}
                    color={"#fff"}
                    textAlign={"center"}
                  >
                    spin
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </FullScreen>
  );
};

export default SpinRobot;
