import { socket } from "../config/network";
// import { DeviceUUID } from "device-uuid";
import axios from "axios";

export const listenToAnswer = async (callback) => {
  try {
    // let uuid = new DeviceUUID().get();
    let uuid = "test_uuid";
    const webhook_uuid = "webhook_" + uuid;

    socket.on(webhook_uuid, (data) => {
      callback && callback(data);
    });
  } catch (err) {
    throw new Error("Something went wrong, please try again later");
  }
};

export const sendTheAnswer = async ({ answer }) => {
  const data = axios.post(
    `https://backend.pyxizhub.com/api/webhook/?uuid=test_uuid&answer=${answer}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  console.log("API response:", data);
};

export const sendSpin = async ({ answer }) => {
  const data = axios.post(
    `https://backend.pyxizhub.com/api/webhook/?uuid=spin_uuid&answer=${answer}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  console.log("API response:", data);
};