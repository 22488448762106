import {
  Box,
  Button,
  CardMedia,
  Container,
  Fade,
  Grid,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import {
  arrow,
  money,
  voteBG,
  logo1,
  bar8,
  bar1,
  bar2,
  bar3,
  bar4,
  bar5,
  bar6,
  bar7,
  one,
  QRcode,
} from "../../Assets";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import Countdown from "react-countdown";
import { listenToAnswer } from "../../services/InteractiveAnswerService";
import { socket } from "../../config/network";
import ImageWithLoader from "../../Components/ImageWithLoader";
import SettingsIcon from "@mui/icons-material/Settings";

const data = [
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Define your financial goals" },
        { id: 2, value: "Ignore investing" },
        { id: 3, value: "Launch an unplanned business" },
        { id: 4, value: "Focus only on short-term gains" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 3, value: "Launch an unplanned business" },
        { id: 4, value: "Focus only on short-term gains" },
        { id: 1, value: "Define your financial goals" },
        { id: 2, value: "Ignore investing" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 2, value: "Ignore investing" },
        { id: 4, value: "Focus only on short-term gains" },
        { id: 3, value: "Launch an unplanned business" },
        { id: 1, value: "Define your financial goals" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Educate yourself about the different asset classes" },
        { id: 2, value: "Rely on 1 source of income" },
        { id: 3, value: "Neglect investment risks" },
        { id: 4, value: "Dismiss diversification" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Dismiss diversification" },
        { id: 3, value: "Neglect investment risks" },
        { id: 2, value: "Rely on 1 source of income" },
        { id: 1, value: "Educate yourself about the different asset classes" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 3, value: "Neglect investment risks" },
        { id: 1, value: "Educate yourself about the different asset classes" },
        { id: 2, value: "Rely on 1 source of income" },
        { id: 4, value: "Dismiss diversification" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Create a budget and allocate investment funds" },
        { id: 2, value: "Neglect expenses tracking" },
        { id: 3, value: "Neglect financial planning" },
        { id: 4, value: "Spend indulgently" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 3, value: "Neglect financial planning" },
        { id: 4, value: "Spend indulgently" },
        { id: 1, value: "Create a budget and allocate investment funds" },
        { id: 2, value: "Neglect expenses tracking" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Spend indulgently" },
        { id: 2, value: "Neglect expenses tracking" },
        { id: 1, value: "Create a budget and allocate investment funds" },
        { id: 3, value: "Neglect financial planning" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Establish an emergency fund" },
        { id: 2, value: "Rely on loans" },
        { id: 3, value: "Avoid having a safety net" },
        { id: 4, value: "Ignore saving monthly" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 2, value: "Rely on loans" },
        { id: 3, value: "Avoid having a safety net" },
        { id: 1, value: "Establish an emergency fund" },
        { id: 4, value: "Ignore saving monthly" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Ignore saving monthly" },
        { id: 1, value: "Establish an emergency fund" },
        { id: 2, value: "Rely on loans" },
        { id: 3, value: "Avoid having a safety net" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Choose a trust-worthy trading platform" },
        { id: 2, value: "Disregard researching" },
        { id: 3, value: "Disregard security credibility" },
        { id: 4, value: "Embrace the instant success mentality" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Embrace the instant success mentality" },
        { id: 3, value: "Disregard security credibility" },
        { id: 2, value: "Disregard researching" },
        { id: 1, value: "Choose a trust-worthy trading platform" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 2, value: "Disregard researching" },
        { id: 4, value: "Embrace the instant success mentality" },
        { id: 1, value: "Choose a trust-worthy trading platform" },
        { id: 3, value: "Disregard security credibility" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Diversify your portfolio to manage risk" },
        { id: 2, value: "Invest mindlessly" },
        { id: 3, value: "Disregard risk appetite" },
        { id: 4, value: "Ignore international markets" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Ignore international markets" },
        { id: 1, value: "Diversify your portfolio to manage risk" },
        { id: 2, value: "Invest mindlessly" },
        { id: 3, value: "Disregard risk appetite" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 2, value: "Invest mindlessly" },
        { id: 3, value: "Disregard risk appetite" },
        { id: 4, value: "Ignore international markets" },
        { id: 1, value: "Diversify your portfolio to manage risk" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Stay up-to-date with market trends" },
        { id: 2, value: "Rely on rumors" },
        { id: 3, value: "Ignore market trends and news" },
        { id: 4, value: "Ignore portfolio review" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Ignore portfolio review" },
        { id: 1, value: "Stay up-to-date with market trends" },
        { id: 3, value: "Ignore market trends and news" },
        { id: 2, value: "Rely on rumors" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 2, value: "Rely on rumors" },
        { id: 4, value: "Ignore portfolio review" },
        { id: 1, value: "Stay up-to-date with market trends" },
        { id: 3, value: "Ignore market trends and news" },
      ],
      answer: 1,
    },
  ],
  [
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 1, value: "Optimize your portfolio regularly" },
        { id: 2, value: "Neglect portfolio monitoring" },
        { id: 3, value: "Rely on intuition only" },
        { id: 4, value: "Dismiss portfolio adjustments" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Dismiss portfolio adjustments" },
        { id: 1, value: "Optimize your portfolio regularly" },
        { id: 3, value: "Rely on intuition only" },
        { id: 2, value: "Neglect portfolio monitoring" },
      ],
      answer: 1,
    },
    {
      question: "Step Closer To Financial Freedom",
      chooses: [
        { id: 4, value: "Dismiss portfolio adjustments" },
        { id: 2, value: "Neglect portfolio monitoring" },
        { id: 1, value: "Optimize your portfolio regularly" },
        { id: 3, value: "Rely on intuition only" },
      ],
      answer: 1,
    },
  ],
];
const TestStand = () => {
  const [animate, setAnimate] = useState(true);
  const [animateBox, setAnimateBox] = useState(true);
  const [step, currentStep] = useState(10);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(Date.now() + 30000 * 1);
  const [choice, setChoice] = useState(null);

  const { t, i18n } = useTranslation(["global"]);

  const startTimer = useCallback(() => {
    setTimeout(() => {
      setAnimate(false);
      setAnimateBox(false);
      next();
    }, 3000);
  }, []);

  const [searchParams] = useSearchParams();
  const lng = searchParams.get("lng");
  const nav = useNavigate();

  useEffect(() => {
    localStorage.setItem("lng", lng || "en");
  }, [i18n, lng]);

  let randomObject = {};
  if (step <= 7) {
    const randomIndex = Math.floor(Math.random() * data[step]?.length);
    randomObject = data[step][randomIndex];
  }
  const next = async () => {
    setTimer(Date.now() + 30000 * 1);
    if (step === 8) {
      setCount(0);
    }
    if (step === 9) {
      setAnimate(true);
      setAnimateBox(true);
    }
    currentStep((prev) => {
      if (prev === 0) return 1;
      if (prev === 1) return 2;
      if (prev === 2) return 3;
      if (prev === 3) return 4;
      if (prev === 4) return 5;
      if (prev === 5) return 6;
      if (prev === 6) return 7;
      if (prev === 7) return 8;
      if (prev === 8) return 9;
      if (prev === 9) return 0;
    });
  };

  const SequentialAnimation = ({ text }) => {
    const words = text.split(" ");
    const [index, setIndex] = useState(0);

    useEffect(() => {
      if (index >= words.length) return;
      const timeout = setTimeout(() => setIndex((i) => i + 1), 170);
      return () => {
        clearTimeout(timeout);
      };
    }, [setIndex, index, words]);

    const placeholder = words.slice(0, index).join(" ");

    return placeholder;
  };

  const renderer = ({ minutes, seconds }) => {
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  const handleClick = (value) => {
    if (value === randomObject?.answer) {
      setCount((e) => e + 1);
    }
    setChoice(value);
    next();
  };
  const handleReset = () => {
    next();
    setAnimate(true);
    setAnimateBox(true);
  };

  useEffect(() => {
    socket.auth = {
      token: localStorage.getItem("token"),
    };
    socket.disconnect().connect();
  }, []);

  useEffect(() => {
    listenToAnswer((data) => {
      const res = JSON.parse(data);
      const answer = Number(res?.answer);
      answer > 0 && answer < 5 && handleClick(answer);
    });
    return () => {
      socket.off("webhook_test_uuid");
    };
  }, []);

  step === 10 &&
    setTimeout(() => {
      currentStep(0);
      setTimer(Date.now() + 30000 * 1);
    }, 4000);

  return (
    <>
      <Box
        sx={{
          height: "100vh",
        }}
      >
        <video
          // src="https://vision.shutterstudio.io/bgVedio.mp4"
          width={"100%"}
          loop
          muted
          autoPlay={true}
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: -3,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            opacity: "20%",
            objectFit: "cover",
          }}
        ></video>
        <img
          src={voteBG}
          alt=""
          width={"98%"}
          style={{
            height: "98vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "absolute",
            zIndex: -2,
            left: "1%",
            top: "1%",
          }}
        />
        <img
          src={money}
          alt=""
          style={{
            borderRadius: "20%",
            width: "10%",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        />
        <img
          src={arrow}
          alt=""
          style={{
            borderRadius: "20%",
            width: "10%",
            position: "absolute",
            left: 15,
            bottom: 0,
          }}
        />
        <Box>
          <Box sx={{ height: "5vh" }}> </Box>

          <Box
            sx={{
              height: "2vh",
              display: "flex",
              justifyContent: "start",
              alignItems: "center ",
              mx: 5,
              mt: 3,
              position:"relative"
            }}
          >
            <img src={logo1} alt="" style={{ width: "300px" }} />{" "}
            <IconButton
              sx={{ position: "absolute", top: 0, right: { lg: 140, md: 80,xs: 0 } }}
              color="success"
              onClick={() => nav("/setting")}
              size="small"
            >
              <SettingsIcon sx={{ height: "20px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              height: "2vh",
              display: "flex",
              justifyContent: "start",
              alignItems: "center ",
              mx: 6,
              mt: 5,
              color: "#023D24",
            }}
          >
            <Typography variant="h6" fontWeight={800}>
              {" "}
              previous choice : {choice}
            </Typography>
          </Box>
        </Box>

        <Container maxWidth={"xl"}>
          {step <= 7 && (
            <>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center ",
                  fontSize: { xs: 25, sm: 30, md: 40 },
                  userSelect: "none",
                  mx: 15,
                }}
                fontWeight={600}
                color={"#023D24"}
              >
                {/* <Countdown
                    date={timer}
                    key={timer}
                    renderer={renderer}
                    onComplete={() => next()}
                  /> */}
                <Box sx={{ py: 2 }}></Box>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    py: { xs: 9, sm: 9, md: 9, lg: 0 },
                  }}
                >
                  <CardMedia
                    image={one}
                    component="img"
                    sx={{
                      width: {
                        sm: "62px",
                        lg: "130px",
                        xs: "32px",
                        md: "100px",
                      },
                      my: 1,
                      mx: 1,
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: 25, sm: 30, md: 50, lg: 60 },
                      userSelect: "none",
                    }}
                    fontWeight={700}
                    color={"#023D24"}
                  >
                    <SequentialAnimation text={randomObject?.question} />
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    pb: { xs: 5, sm: 5, md: 5, lg: 0 },
                  }}
                >
                  {step === 0 && (
                    <ImageWithLoader
                      src={bar1}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 1 && (
                    <ImageWithLoader
                      src={bar2}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 2 && (
                    <ImageWithLoader
                      src={bar3}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 3 && (
                    <ImageWithLoader
                      src={bar4}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 4 && (
                    <ImageWithLoader
                      src={bar5}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 5 && (
                    <ImageWithLoader
                      src={bar6}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 6 && (
                    <ImageWithLoader
                      src={bar7}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                  {step === 7 && (
                    <ImageWithLoader
                      src={bar8}
                      alt={""}
                      style={{ width: "70%" }}
                    />
                  )}
                </Box>
              </Box>

              <Grid
                container
                spacing={8}
                sx={{ mt: { xs: 5, sm: 5, md: 5, lg: 0 } }}
              >
                {randomObject?.chooses?.map((e, i) => (
                  <Grid item xs={12} lg={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => handleClick(e?.id)}
                    >
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mx: 1,
                          userSelect: "none",
                        }}
                        // bgcolor={"#365A4A"}
                      >
                        <Typography
                          fontWeight={700}
                          variant="h5"
                          // color={"#fff"}
                        >
                          {i + 1} -
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "2.5px solid #A7B136",

                          borderRadius: 5,
                          width: { md: "400px", sm: "300px", xs: "250px" },
                          height: { md: "80px", sm: "65px", xs: "60px" },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          userSelect: "none",
                          cursor: "pointer",
                        }}
                      >
                        <Typography
                          fontWeight={700}
                          sx={{
                            fontSize: {
                              md: "27px",
                              sm: "17px",
                              xs: "15px",
                            },
                          }}
                          color={"#023D24"}
                          textAlign={"center"}
                        >
                          {e?.value}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
          {step === 8 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "90vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      py: 6,
                      height: 1,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: { xs: 40, sm: 50, md: 60 }, mx: 1 }}
                      fontWeight={600}
                      color={"#023D24"}
                    >
                      {t("vote.Score")} :
                    </Typography>
                    <Typography
                      sx={{ fontSize: { xs: 40, sm: 50, md: 60 } }}
                      fontWeight={600}
                      color={"#A7B136"}
                    >
                      ({count})
                    </Typography>
                  </Box>
                  <img src={QRcode} alt="" width={"79%"} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      pt: 5,
                    }}
                    onClick={() => handleReset()}
                  >
                    <Box
                      sx={{
                        border: "2.5px solid #A7B136",
                        borderRadius: 5,
                        width: { md: "400px", sm: "300px", xs: "250px" },
                        height: { md: "80px", sm: "65px", xs: "60px" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        fontWeight={700}
                        sx={{
                          fontSize: {
                            md: "27px",
                            sm: "17px",
                            xs: "15px",
                          },
                        }}
                        color={"#023D24"}
                        textAlign={"center"}
                      >
                        {t("vote.reset")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {step === 9 && (
            <Fade
              direction="down"
              easing="ease-in-out"
              in={animate}
              appear={false}
              timeout={{ exit: 300 }}
              mountOnEnter
              unmountOnExit
              style={{
                transformOrigin: "0 0 0",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                zIndex: 99999,
              }}
            >
              <Box
                sx={{ height: "100vh", backgroundColor: "background.paper" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                justifyItems="center"
                flexDirection="column"
                position={"relative"}
                overflow={"hidden"}
              >
                <video
                  // src="https://vision.shutterstudio.io/bgVedio.mp4"
                  loop
                  muted
                  autoPlay={true}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "fixed",
                    zIndex: -1,
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    opacity: "20%",
                    objectFit: "cover",
                  }}
                ></video>
                <Box
                  sx={{
                    width: "1162px",
                    height: "319px",
                    position: "absolute",
                    top: " -241.69px",
                    zIndex: -2,

                    left: "-400px",
                    bgcolor: "#A7B136",
                    transform: "rotate(132.63deg)",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "1162px",
                    height: "319px",
                    zIndex: -2,
                    position: "absolute",
                    bottom: " -241.69px",
                    right: "-400px",
                    bgcolor: "#A7B136",
                    transform: "rotate(132.63deg)",
                  }}
                ></Box>
                <Fade
                  direction="down"
                  easing="ease-in-out"
                  in={animateBox}
                  appear={true}
                  timeout={{ exit: 300, enter: 1000, appear: 300 }}
                  mountOnEnter
                  unmountOnExit
                  style={{
                    transformOrigin: "0 0 0",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    justifyItems="center"
                    flexDirection="column"
                  >
                    <img
                      src={logo1}
                      alt="pyxiz"
                      height={100}
                      onLoad={startTimer}
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: 25, sm: 30, md: 50, lg: 60 },
                        userSelect: "none",
                        py: 2,
                      }}
                      fontWeight={700}
                      color={"#023D24"}
                    >
                      {/* One Step Closer To Financial Freedom */}
                    </Typography>
                  </Box>
                </Fade>
              </Box>
            </Fade>
          )}
        </Container>
      </Box>
    </>
  );
};

export default TestStand;
