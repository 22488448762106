import { BrowserRouter } from "react-router-dom";
import { Box, Fade, Grow, Typography } from "@mui/material";
import Router from "./Router";

import { useCallback, useEffect } from "react";

import { useState } from "react";
import { logo1, splashIcon } from "./Assets";

export default function Splash({ colorMode, mode, isRTL }) {
  const path = window.location.pathname;

  return (
    <>
      {path !== "/" &&
        path !== "/test" &&
        path !== "/test-stand" &&
        path !== "/spin-robot" &&
        path !== "/vote-test" && <PyxizSplash />}
      {(path === "/test" || path === "/test-stand") && <PyxizSplashTest />}

      <Box
        sx={{
          position: "absolute",
          zIndex: -9,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: "background.secondary",
        }}
      ></Box>
      <BrowserRouter>
        <Router changeMode={colorMode} mode={mode} isRTL={isRTL} />
      </BrowserRouter>
    </>
  );
}

export const AppLoader = ({ loader }) => {
  return (
    <Box
      sx={{ height: "100vh" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      justifyItems="center"
    >
      {loader || <img src={splashIcon} alt="pyxiz" height={84} />}
    </Box>
  );
};

const PyxizSplash = () => {
  const [animate, setAnimate] = useState(true);
  const [animateBox, setAnimateBox] = useState(false);
  setTimeout(() => setAnimateBox(true), 1000);

  const startTimer = useCallback(() => {
    setTimeout(() => {
      setAnimate(false);
      setAnimateBox(false);
    }, 3000);
  }, []);

  return (
    <Fade
      direction="down"
      easing="ease-in-out"
      in={animate}
      appear={false}
      timeout={{ exit: 300 }}
      mountOnEnter
      unmountOnExit
      style={{
        transformOrigin: "0 0 0",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 99999,
      }}
    >
      <Box
        sx={{ height: "100vh", backgroundColor: "background.paper" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        flexDirection="column"
        position={"relative"}
        overflow={"hidden"}
      >
        <video
          // src="https://vision.shutterstudio.io/bgVedio.mp4"
          loop
          muted
          autoPlay={true}
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: -1,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            opacity: "20%",
            objectFit: "cover",
          }}
        ></video>
        <Box
          sx={{
            width: "1162px",
            height: "319px",
            position: "absolute",
            top: " -241.69px",
            zIndex: -2,

            left: "-400px",
            bgcolor: "#A7B136",
            transform: "rotate(132.63deg)",
          }}
        ></Box>
        <Box
          sx={{
            width: "1162px",
            height: "319px",
            zIndex: -2,
            position: "absolute",
            bottom: " -241.69px",
            right: "-400px",
            bgcolor: "#A7B136",
            transform: "rotate(132.63deg)",
          }}
        ></Box>
        <Fade
          direction="down"
          easing="ease-in-out"
          in={animateBox}
          appear={true}
          // timeout={3000}
          timeout={{ exit: 300, enter: 1000, appear: 300 }}
          mountOnEnter
          unmountOnExit
          style={{
            transformOrigin: "0 0 0",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            flexDirection="column"
          >
            <img
              src={splashIcon}
              alt="pyxiz"
              height={100}
              onLoad={startTimer}
            />
            <Typography
              sx={{
                fontSize: { xs: 25, sm: 30, md: 50, lg: 60 },
                userSelect: "none",
                py: 2,
              }}
              fontWeight={700}
              color={"#023D24"}
            >
              One Step Closer To Financial Freedom
            </Typography>
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
};

const PyxizSplashTest = () => {
  const [animate, setAnimate] = useState(true);
  const [animateBox, setAnimateBox] = useState(false);
  setTimeout(() => setAnimateBox(true), 1000);

  const startTimer = useCallback(() => {
    setTimeout(() => {
      setAnimate(false);
      setAnimateBox(false);
    }, 3000);
  }, []);

  return (
    <Fade
      direction="down"
      easing="ease-in-out"
      in={animate}
      appear={false}
      timeout={{ exit: 300 }}
      mountOnEnter
      unmountOnExit
      style={{
        transformOrigin: "0 0 0",
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 99999,
      }}
    >
      <Box
        sx={{ height: "100vh", backgroundColor: "background.paper" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        justifyItems="center"
        flexDirection="column"
        position={"relative"}
        overflow={"hidden"}
      >
        <video
          // src="https://vision.shutterstudio.io/bgVedio.mp4"
          loop
          muted
          autoPlay={true}
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            zIndex: -1,
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            opacity: "20%",
            objectFit: "cover",
          }}
        ></video>
        <Box
          sx={{
            width: "1162px",
            height: "319px",
            position: "absolute",
            top: " -241.69px",
            zIndex: -2,

            left: "-400px",
            bgcolor: "#A7B136",
            transform: "rotate(132.63deg)",
          }}
        ></Box>
        <Box
          sx={{
            width: "1162px",
            height: "319px",
            zIndex: -2,
            position: "absolute",
            bottom: " -241.69px",
            right: "-400px",
            bgcolor: "#A7B136",
            transform: "rotate(132.63deg)",
          }}
        ></Box>
        <Fade
          direction="down"
          easing="ease-in-out"
          in={animateBox}
          appear={true}
          // timeout={3000}
          timeout={{ exit: 300, enter: 1000, appear: 300 }}
          mountOnEnter
          unmountOnExit
          style={{
            transformOrigin: "0 0 0",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            flexDirection="column"
          >
            <img src={logo1} alt="pyxiz" height={100} onLoad={startTimer} />
            {/* <Typography
              sx={{
                fontSize: { xs: 25, sm: 30, md: 50, lg: 60 },
                userSelect: "none",
                py: 2,
              }}
              fontWeight={700}
              color={"#023D24"}
            >
              One Step Closer To Financial Freedom
            </Typography> */}
          </Box>
        </Fade>
      </Box>
    </Fade>
  );
};
