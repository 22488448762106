export const questions = [
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Define your financial goals" },
          { id: 2, value: "Ignore investing" },
          { id: 3, value: "Launch an unplanned business" },
          { id: 4, value: "Focus only on short-term gains" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Launch an unplanned business" },
          { id: 2, value: "Focus only on short-term gains" },
          { id: 3, value: "Define your financial goals" },
          { id: 4, value: "Ignore investing" },
        ],
        answer: 3,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Ignore investing" },
          { id: 2, value: "Focus only on short-term gains" },
          { id: 3, value: "Launch an unplanned business" },
          { id: 4, value: "Define your financial goals" },
        ],
        answer: 4,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Educate yourself about the different asset classes" },
          { id: 2, value: "Rely on 1 source of income" },
          { id: 3, value: "Neglect investment risks" },
          { id: 4, value: "Dismiss diversification" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Dismiss diversification" },
          { id: 2, value: "Neglect investment risks" },
          { id: 3, value: "Rely on 1 source of income" },
          { id: 4, value: "Educate yourself about the different asset classes" },
        ],
        answer: 4,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Neglect investment risks" },
          { id: 2, value: "Educate yourself about the different asset classes" },
          { id: 3, value: "Rely on 1 source of income" },
          { id: 4, value: "Dismiss diversification" },
        ],
        answer: 2,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Create a budget and allocate investment funds" },
          { id: 2, value: "Neglect expenses tracking" },
          { id: 3, value: "Neglect financial planning" },
          { id: 4, value: "Spend indulgently" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Neglect financial planning" },
          { id: 2, value: "Spend indulgently" },
          { id: 3, value: "Create a budget and allocate investment funds" },
          { id: 4, value: "Neglect expenses tracking" },
        ],
        answer: 3,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Spend indulgently" },
          { id: 2, value: "Neglect expenses tracking" },
          { id: 3, value: "Create a budget and allocate investment funds" },
          { id: 4, value: "Neglect financial planning" },
        ],
        answer: 3,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Establish an emergency fund" },
          { id: 2, value: "Rely on loans" },
          { id: 3, value: "Avoid having a safety net" },
          { id: 4, value: "Ignore saving monthly" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Rely on loans" },
          { id: 2, value: "Avoid having a safety net" },
          { id: 3, value: "Establish an emergency fund" },
          { id: 4, value: "Ignore saving monthly" },
        ],
        answer: 3,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Ignore saving monthly" },
          { id: 2, value: "Establish an emergency fund" },
          { id: 3, value: "Rely on loans" },
          { id: 4, value: "Avoid having a safety net" },
        ],
        answer: 2,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Choose a trust-worthy trading platform" },
          { id: 2, value: "Disregard researching" },
          { id: 3, value: "Disregard security credibility" },
          { id: 4, value: "Embrace the instant success mentality" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Embrace the instant success mentality" },
          { id: 2, value: "Disregard security credibility" },
          { id: 3, value: "Disregard researching" },
          { id: 4, value: "Choose a trust-worthy trading platform" },
        ],
        answer: 4,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Disregard researching" },
          { id: 2, value: "Embrace the instant success mentality" },
          { id: 3, value: "Choose a trust-worthy trading platform" },
          { id: 4, value: "Disregard security credibility" },
        ],
        answer: 3,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Diversify your portfolio to manage risk" },
          { id: 2, value: "Invest mindlessly" },
          { id: 3, value: "Disregard risk appetite" },
          { id: 4, value: "Ignore international markets" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Ignore international markets" },
          { id: 2, value: "Diversify your portfolio to manage risk" },
          { id: 3, value: "Invest mindlessly" },
          { id: 4, value: "Disregard risk appetite" },
        ],
        answer: 2,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Invest mindlessly" },
          { id: 2, value: "Disregard risk appetite" },
          { id: 3, value: "Ignore international markets" },
          { id: 4, value: "Diversify your portfolio to manage risk" },
        ],
        answer: 4,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Stay up-to-date with market trends" },
          { id: 2, value: "Rely on rumors" },
          { id: 3, value: "Ignore market trends and news" },
          { id: 4, value: "Ignore portfolio review" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Ignore portfolio review" },
          { id: 2, value: "Stay up-to-date with market trends" },
          { id: 3, value: "Ignore market trends and news" },
          { id: 4, value: "Rely on rumors" },
        ],
        answer: 2,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Rely on rumors" },
          { id: 2, value: "Ignore portfolio review" },
          { id: 3, value: "Stay up-to-date with market trends" },
          { id: 4, value: "Ignore market trends and news" },
        ],
        answer: 3,
      },
    ],
    [
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Optimize your portfolio regularly" },
          { id: 2, value: "Neglect portfolio monitoring" },
          { id: 3, value: "Rely on intuition only" },
          { id: 4, value: "Dismiss portfolio adjustments" },
        ],
        answer: 1,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Dismiss portfolio adjustments" },
          { id: 2, value: "Optimize your portfolio regularly" },
          { id: 3, value: "Rely on intuition only" },
          { id: 4, value: "Neglect portfolio monitoring" },
        ],
        answer: 2,
      },
      {
        question: "Step Closer To Financial Freedom",
        chooses: [
          { id: 1, value: "Dismiss portfolio adjustments" },
          { id: 2, value: "Neglect portfolio monitoring" },
          { id: 3, value: "Optimize your portfolio regularly" },
          { id: 4, value: "Rely on intuition only" },
        ],
        answer: 3,
      },
    ],
  ];