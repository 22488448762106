import { Typography, Grid, Box, Container, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { questions as data } from "./questions";
import { sendTheAnswer } from "../../services/InteractiveAnswerService";
import { QRcode, splashIcon } from "../../Assets";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FullScreen } from "@chiragrupani/fullscreen-react";
import SettingsIcon from "@mui/icons-material/Settings";

const VoteTest = () => {
  const [animate, setAnimate] = useState(true);
  const [animateBox, setAnimateBox] = useState(true);
  const [step, currentStep] = useState(0);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(Date.now() + 30000 * 1);
  const [isFullScreen, setFullScreen] = useState(false);

  const { t, i18n } = useTranslation(["global"]);

  const [searchParams] = useSearchParams();
  const lng = searchParams.get("lng");

  const [activeButton, setActiveButton] = useState(0);
  const [randomObject, setRandomObject] = useState({ question: "" });

  useEffect(() => {
    if (step <= 7) {
      const randomIndex = Math.floor(Math.random() * data[step]?.length);
      setRandomObject(data[step][randomIndex]);
    }
  }, [step]);

  const next = useCallback(async () => {
    currentStep((prev) => {
      if (prev === 0) return 1;
      if (prev === 1) return 2;
      if (prev === 2) return 3;
      if (prev === 3) return 4;
      if (prev === 4) return 5;
      if (prev === 5) return 6;
      if (prev === 6) return 7;
      if (prev === 7) return 8;
      if (prev === 8) return 9;
      if (prev === 9) return 0;
    });
  }, [step]);

  const handleClick = useCallback(
    async (value) => {
      await sendTheAnswer({ answer: value });

      next();
    },
    [randomObject]
  );
  const startTimer = useCallback(() => {
    setTimeout(() => {
      setAnimate(false);
      setAnimateBox(false);
      next();
    }, 3000);
  }, [next]);

  const handleReset = () => {
    next();
    setAnimate(true);
    setAnimateBox(true);
  };
  return (
    <FullScreen
      isFullScreen={isFullScreen}
      onChange={(isFull) => {
        setFullScreen(isFull);
      }}
    >
      <Box sx={{ backgroundColor: "black", width: 1 }}>
        {isFullScreen === false && (
          <Button onClick={(e) => setFullScreen(!isFullScreen)}>
            Go Fullscreen
          </Button>
        )}
        <Box
          sx={{
            position: "absolute",
            zIndex: -4,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "black",
          }}
        ></Box>
        <Container>
          <Grid
            container
            spacing={5}
            sx={{ mt: { xs: 5, sm: 5, md: 5, lg: 0 } }}
          >
            <Grid
              item
              xs={12}
              sx={{
                height: {
                  lg: "450px",
                  md: "300px",
                  sm: "250px",
                  xl: "500px",
                  xs: "200px",
                },
                width: "100%",
              }}
            >
              <video
                src="https://game.shutterstudio.io/InShot.mp4"
                loop
                muted
                autoPlay={true}
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
              ></video>
            </Grid>
            {[1, 2, 3, 4]?.map((e, i) => (
              <Grid item xs={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleClick(e)}
                >
                  <Box
                    sx={{
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mx: 1,
                      userSelect: "none",
                    }}
                    // bgcolor={"#365A4A"}
                  >
                    {/* <Typography fontWeight={700} variant="h5" color={"#fff"}>
                        {e} -
                      </Typography> */}
                  </Box>
                  <Box
                    sx={{
                      border: "2.5px solid #A7B136",

                      borderRadius: 5,
                      width: { md: "400px", sm: "300px", xs: "250px" },

                      height: { md: "80px", sm: "65px", xs: "60px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor:
                        activeButton === e ? "#A7B13688" : "#fff",
                      userSelect: "none",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      fontWeight={700}
                      sx={{
                        fontSize: {
                          md: "27px",
                          sm: "17px",
                          xs: "15px",
                        },
                      }}
                      color={"#023D24"}
                      textAlign={"center"}
                    >
                      {e}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </FullScreen>
  );
};

export default VoteTest;
