// General constants
export const COMPANY_NAME = "Shutter Stop Events";
export const MAIN_URL = "/projects";

// Network constants
export const BASE_URL = "https://chatbot-api.limberx.com/api";
export const SOCKET_API_URL = "https://backend.pyxizhub.com/api";
export const SOCKET_URL = "wss://backend.pyxizhub.com";
export const REQUEST_TIMEOUT = 10 * 1000; // 10 secs

// Firebase
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDLwpPno4s55XtNF9VUbNrLaCbbngRHaPw",
  authDomain: "shutter-stop-events-1.firebaseapp.com",
  projectId: "shutter-stop-events-1",
  storageBucket: "shutter-stop-events-1.appspot.com",
  messagingSenderId: "187870187972",
  appId: "1:187870187972:web:2c7c2a8809f193b6ee9491",
  measurementId: "G-FPZJMNK2P4",
};

// Pentavalue contact details
export const COMPANY_FACEBOOK = "https://www.facebook.com/ShutterStopEgypt/";
export const COMPANY_LINKEDIN = "https://www.linkedin.com/company/pentavalue/";

// Keys
export const LOCAL_STORAGE_STATE_KEY = "SHUTTER_STOP_EVENTS";

// Ui constants
export const TOAST_TIMEOUT = 4 * 1000; // 4 secs

export const DEFAULT_INTERACTIVE_VIDEO_WIDTH = 800;
export const DEFAULT_INTERACTIVE_VIDEO_HEIGHT = 500;
